<template lang="pug">
    base-page
        template(v-slot:titlu)
            span Zile libere
        template(v-slot:leftmenu)
            el-tooltip(content="Export excel")
                a(class='el-button el-tooltip el-button--success el-button--small is-circle' target='_blank' :href="get_excel_href()") 
                    i.el-icon-document
        el-card(id='filtre')
            el-row( :gutter="10" )
                el-col(:md="24")
                    el-button( size='mini' icon='el-icon-arrow-left' @click='prevMonth()')
                    el-date-picker( class='luna-curenta' size='mini' v-model="Filters.Data" type="month" placeholder="Alege un an" format='MMMM yyyy' value-format='yyyy-MM' @change='refresh_info()')
                    el-button( size='mini' icon='el-icon-arrow-right' @click='nextMonth()')
                    el-checkbox(v-model="Filters.Activi" style="margin-left: 15px" @change='checkAngajat()' label='Activi')
                    el-checkbox(v-model="Filters.Inactivi" style="margin-left: 15px" @change='checkAngajat()' label='Inactivi')
                
                
        div.table-wrapper()
            table( class='tabel' v-loading="loadingVisible" v-if="!faraAngajati")
                thead
                    tr
                        th.index-column # 
                        th.name-column Nume
                        th.day-column(v-for='(z,idx) in raport.zile' :key="'cell-header-zi-' + idx" align='center' )
                            span {{formatDayName(z)}}
                                br
                            span {{formatDay(z)}}
                                br
                            button.no-border-btn( :title="isZiLiberaNational(z) ? 'Scoate ziua libera' : 'Adauga zi libera national'" @click='toggle_zi_nationala(z)' )
                                i( :class="isZiLiberaNational(z) ? 'el-icon-remove-outline' : 'el-icon-circle-plus-outline'" )
                tbody
                    tr( v-for='(row,idx) in raport.angajati' :key="'row-' + row.Id + '-zi-' + idx")
                        td.index-column {{idx+1}}.
                        td.name-column {{row.Nume}}
                        td( v-for='(z,idx2) in raport.zile' :key="'cell-' + row.Id + '-zi-' + idx2" 
                            align='center' 
                            @click='show_dialog(row, z)'
                            :class='row.zile[z].Class' :title='row.zile[z].Tooltip' 
                            :style='{ backgroundColor: row.zile[z].Culoare }' 
                            v-html='row.zile[z].Text' )

            div(v-else style="font-weight: bold; color: #780e0e; margin: 30px") Nu a fost gasit nici un angajat! 
        
        zi-libera-dialog( @save='refresh_info' ref="dlg" )
</template>

<script>
import settings from "../backend/LocalSettings";
import BasePage from "../pages/BasePage";
import ZiLiberaDialog from '../dialogs/ZiLiberaDialog';
import moment from 'moment';
import TitluPagina from '../widgets/TitluPagina';

export default {
  name: "departamente",
  extends: BasePage,
  components: {
      'base-page': BasePage,
      'zi-libera-dialog': ZiLiberaDialog,
      'titlu-pagina': TitluPagina
  },
  data () {
    return { 
        Filters: { Data: moment().format("YYYY-MM"), Activi: true, Inactivi: false},
        numeZile: [ 'Du', 'Lu', 'Ma', 'Mi', 'Jo', 'Vi','Sa'],
        cacheZileNationale: {},
        raport: {
            angajati: [],
            zileLibereNationale:[],
            zile: []
        },
        faraAngajati: false
    }
  },
  methods: {
    chunk(arrayToSplit,chunkSize) {
        const chunks = [];
        
        for (let i = 0; i < arrayToSplit.length; i += chunkSize) {
            if( i < arrayToSplit.length ){
                const tempArray = arrayToSplit.slice(i, i + chunkSize);
                chunks.push(tempArray);
            }
        }
        
        return chunks;
    },
      async get_info() {
          this.refresh_info();
      },

      async refresh_info() {
        this.cacheZileNationale = {};
        this.raport.zile      = [];
        this.raport.angajati  = [];

        var response = await this.post('zile_libere/refresh_info', this.Filters );
        console.log(response)
        this.raport.zileLibereNationale = response.zileLibereNationale;
        this.raport.zile                = response.zile;
        //quick fix pt afisare multe randuri - adaugam cate 50 de randuri cu timeout
        // this.raport.angajati            = response.angajati;
        this.raport.angajati            = [];
        if(response.angajati == undefined){
            this.faraAngajati = true
        }else {
            let chunks = this.chunk(response.angajati, 50);
            chunks.forEach((chunk,i) => {
            setTimeout(() => {
                chunk.forEach(a => {
                this.raport.angajati.push(a);
                });
            }, i * 200);
            });
        }
      },


      formatDay(date) {
          return moment(date).format("DD");
      },

      formatDayName(date) {
          return this.numeZile[ moment(date).weekday() ];
      },

      show_dialog(angajat, zi){
        var infoZi = angajat.zile[zi];        
        if( infoZi.ZiLibera ) {
            this.$refs.dlg.show_me( 'edit', { IdAngajat: angajat.Id, Id: infoZi.IdZiLibera });
        } else {
            this.$refs.dlg.show_me( 'add' , { StartConcediu: zi, SfarsitConcediu: zi, IdAngajat: angajat.Id, IdTip:-1 } );
        }
      },

      isZiLiberaNational(zi) {
        let momentDate      = moment(zi);
        var ret             = false;
        var zileNationale   = this.raport.zileLibereNationale;
        
        for( var i = 0; i < zileNationale.length; i++ ) {
            var ziNat = zileNationale[i];
            if( momentDate.isSame(ziNat) ){
                ret = true;
                break;
            }
        };
        return ret;
      },

      prevMonth(){
          this.Filters.Data = moment(this.Filters.Data, "YYYY-MM" ).add(-1, 'month').format("YYYY-MM");
          this.refresh_info();
      },

      nextMonth(){
          this.Filters.Data = moment(this.Filters.Data, "YYYY-MM" ).add(1, 'month').format("YYYY-MM");
          this.refresh_info();
      },
      checkAngajat(){
        this.refresh_info()
        if(this.Filters.Activi == false && this.Filters.Inactivi == false){
            this.faraAngajati = true
        }else {
            this.faraAngajati = false;
        }
        console.log(this.Filters.Activi)
        console.log(this.Filters.Inactivi)

          
      },

        async toggle_zi_nationala(d){
            
            const _mode = this.isZiLiberaNational(d) ? 'remove' : 'add';
            await this.post('zile_libere/toggle_zi_nationala', { Data: d, Mode: _mode });
            this.refresh_info();
        },
    
      get_excel_href() {
        var encoded     = encodeURIComponent( JSON.stringify( this.Filters ) );
        var id_firma    = settings.get_id_firma();
        return `${settings.get_base_url()}zile_libere/excel/${id_firma}/${encoded}`;
      },  
  },
  mounted() {
      settings.verify_login_and_redirect(this);
  }
};
</script>

<style lang="less" scoped>
    .luna-curenta{
        width: 180px !important;
        input[type='text']{
            text-align: center !important;
        }
    }


    .table-wrapper{
        height: calc( 100vh - 220px );
        overflow-y: scroll;
        padding: 0px 15px;
        background-color: white;
        

        .tabel {
            border-collapse: collapse;
            table-layout: fixed;
            background-color: white;
            min-width: 100%;

            .index-column{
                width: 50px;
                text-align: right;
                padding-right: 5px;
            }
            .name-column{
                width: 250px;
            }

            thead{
                th{
                    // background: fade(white,85%);
                    border: 1px solid fade(rgb(0, 0, 0), 30%);
                    text-align: center;
                    position: sticky;
                    top: 0;
                }
                
            }
            tbody {
                td{
                    border: 1px solid fade(rgb(0, 0, 0), 70%);
                }
            }
        }
    }
    .no-border-btn{
        border: none;
        background: none;

        &:hover{
            background: gray;
            color: white;
        }
    }
    .zi-normala, .zi-weekend, .zi-libera-national{
        transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s all;
        cursor: pointer;
        &:hover{
            background-color: fade(red,50%) !important;
        }
    }
    
</style>
